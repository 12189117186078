<template>
   <span>
      <div v-if="isLoading" class="spinner">
         <v-progress-circular indeterminate size="16"></v-progress-circular>
      </div>
      <v-menu v-else v-model="isOpen" offset-y class="notification-list">
         <!-- unclear intent -->
         <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
         <template #activator="{ on }" :offset-x="true" :close-on-content-click="false" :nudge-width="200">
            <v-btn icon v-on="on">
               <v-icon>mdi-earth</v-icon>
            </v-btn>
         </template>
         <v-card>
            <v-card-text>
               <v-list dense>
                  <v-list-item v-for="item in languages" :key="item.id" link dense @click="languageSelected(item)">
                     <v-list-item-content>
                        <span :class="{ 'font-weight-bold': selectedLanguage && item.id === selectedLanguage.id }">
                           {{ item.name }}
                        </span>
                     </v-list-item-content>
                  </v-list-item>
               </v-list>
            </v-card-text>
         </v-card>
      </v-menu>
   </span>
</template>

<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { UserApi, LanguagePublicModel } from "@backend/api/pmToolApi";
import AuthStore from "@backend/store/authStore.ts";
import BaseResponse from "@models/BaseResponse";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import globalStore from "@backend/store/globalStore";
import TranslationCachedApi from "@backend/store/apiCache/TranslationCachedApi";
import UserCachedApi from "@backend/store/apiCache/UserCachedApi";
import translationsStore from "@utils/TranslationUtils";

@Component({
   name: "LangSelector",
   components: {},
})
export default class LangSelector extends ComponentBase {
   selectedLanguage: LanguagePublicModel | null = null;

   isOpen: boolean = false;
   userId: string = "";
   loading: boolean = false;

   languages: LanguagePublicModel[] = [];

   get isLoading() {
      return translationsStore.isLoading;
   }

   @Watch("isOpen")
   onOpen(newVal) {
      if (newVal == true) {
         void this.loadLanguagues();
      }
   }

   languageSelected(language: LanguagePublicModel) {
      void this.updateUserLanguage(language);
   }

   mounted() {
      void this.loadLanguagues();
      this.userId = AuthStore.getUserId()!;
      void this.loadPreferredLanguage();
   }

   setPreferredLanguage(language: LanguagePublicModel | undefined) {
      if (!language) return;

      if (this.selectedLanguage != language) {
         this.selectedLanguage = language;

         if (language?.id !== globalStore.getLanguage()?.id) {
            globalStore.setLanguage(this.selectedLanguage);
            void translationsStore.loadTranslations(globalStore.getLanguage().code!);
         }
      }
   }

   //API
   async loadLanguagues() {
      this.loading = true;
      try {
         var response = await TranslationCachedApi.getActiveLanguages();
         this.languages = response;
      } catch (err) {
         let error = err as BaseResponse;
         console.log("API User lang load error:", error);
         this.notifyError(error, "load", "List of languages");
      }
      this.loading = false;
   }

   async loadPreferredLanguage(): Promise<void> {
      try {
         let preferredLanguage = await UserCachedApi.getCurrentUserPreferredLanguage();
         this.setPreferredLanguage(preferredLanguage);
      } catch (err) {
         let error = err as BaseResponse;
         console.log("API Current User preferred lang load error:", error);
         this.notifyError(error, "load", "Current User preferred Language");
         throw err;
      }
   }

   async updateUserLanguage(language: LanguagePublicModel): Promise<void> {
      try {
         let preferredLanguage = await UserApi.updatePreferredLanguage(this.userId, language);
         UserCachedApi.invalidatePreferredLanguage();
         this.setPreferredLanguage(preferredLanguage);
      } catch (error) {
         this.notifyError(error, "update", "Users preferred langauge");
      }
   }
}
</script>

<style scoped>
.selector-img {
   width: 40px;
   height: 30px;
}

.selected-img {
   width: 40px;
   height: 30px;
}

.notification-list {
   z-index: 15;
   background-color: rgb(245, 245, 245) !important;
}

.spinner {
   display: flex;
   width: 48px;
   justify-content: center;
}
</style>
