import { TranslationApi } from "@backend/api/pmToolApi";
import { TranslationKey } from "@root/translations";
import Vue from "vue";

export const defaultLanguageCode = "en";

export function getLanguageIso1(languageName: string, defaultResult = defaultLanguageCode) {
   return (
      {
         English: "en",
         Czech: "cs",
         Chinese: "zh",
         German: "de",
      }[languageName] ?? defaultResult
   );
}

const translationsState = Vue.observable({
   translations: {} as Record<string, string>,
   translationsReloadKey: 0,
   isLoading: false,
});

const translationsStore = {
   getTranslationValue(key: TranslationKey): string {
      return translationsState.translations[key] ?? key;
   },
   async loadTranslations(languageCode: string): Promise<void> {
      translationsState.translations = JSON.parse(localStorage.getItem(`translations-${languageCode}`) ?? "{}");
      translationsState.translationsReloadKey++;
      translationsState.isLoading = true;
      try {
         const freshTranslations = await TranslationApi.getAllSimpleTranslationsForLanguage(languageCode);
         localStorage.setItem(`translations-${languageCode}`, JSON.stringify(freshTranslations));
         translationsState.translations = freshTranslations;
         translationsState.translationsReloadKey++;
      } finally {
         translationsState.isLoading = false;
      }
   },
   translationsReloadKey(): number {
      return translationsState.translationsReloadKey;
   },
   get isLoading() {
      return translationsState.isLoading;
   },
};

export default translationsStore;
