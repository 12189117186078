<template>
   <v-app id="app-root" :class="{ 'app-scroll': !isMaintenanceCapture }">
      <nav-menu v-if="!isMaintenanceCapture" :mini="navMenuMini || $vuetify.breakpoint.mobile"></nav-menu>

      <v-main class="bc-color">
         <v-alert v-if="isMaintenance && isKeycloakAdmin" type="info" text dismissible>
            System maintenance (admin access)
         </v-alert>
         <top-bar v-if="!isMaintenanceCapture" @toggle-nav-menu="toggleNavMenu"></top-bar>
         <router-view :key="$route.fullPath"></router-view>
         <!--<chat-window></chat-window>-->

         <v-snackbar
            v-for="(snackbar, index) in snackbars"
            :key="index"
            v-model="snackbar.active"
            :timeout="snackbar.color == 'error' ? -1 : 2500"
            :color="snackbar.color"
            app
            bottom
            right
            :style="'padding-bottom: ' + index * 60 + 'px'"
         >
            <span v-if="snackbar.color === 'error'" class="grey--text text--lighten-2 mr-1">
               [{{ snackbar.timestamp }}]
            </span>
            <span class="snack-break">{{ snackbar.text }}</span>
            <template v-if="snackbar.color == 'error'" #action="{ attrs }">
               <v-btn icon v-bind="attrs" small @click="hideSnack(snackbar)">
                  <v-icon small>mdi-close</v-icon>
               </v-btn>
            </template>
         </v-snackbar>

         <keycloak-logout-timer />
      </v-main>
   </v-app>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import NavMenu from "@components/NavMenu/nav-menu.vue";
import TopBar from "@components/top-bar.vue";
import ChatWindow from "@components/Chat/chat-window.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import KeycloakLogoutTimer from "@components/Shared/keycloak-logout-timer.vue";
import moment from "moment";
import MaintenanceUtils from "@utils/MaintenanceUtils";
import { debounce } from "lodash";

@Component({
   components: {
      NavMenu,
      TopBar,
      ChatWindow,
      KeycloakLogoutTimer,
   },
})
export default class AppRoot extends Vue {
   constructor() {
      super();
   }

   navMenuMini = false;
   isMaintenance = false;
   isKeycloakAdmin = false;

   get isMaintenanceCapture(): boolean {
      return this.isMaintenance && !this.isKeycloakAdmin;
   }

   snackbars: any[] = [];

   toggleNavMenu() {
      this.navMenuMini = !this.navMenuMini;
   }

   mounted() {
      this.isMaintenance = MaintenanceUtils.isMaintenance;
      this.isKeycloakAdmin = MaintenanceUtils.isKeycloakAdmin;

      EventBus.$on(Events.DisplayToast, (message) => {
         this.snackbars = this.snackbars.filter((x) => x.active);
         this.snackbars.push({
            color: message.color ? message.color : "info",
            text: message.text,
            active: true,
            timestamp: moment().format("DD.MM.YYYY HH:mm:ssZ"),
         });
      });

      EventBus.$on(Events.AppInsightsTrack, (record) => {
         this.$appInsights.trackEvent(record.event, { value: record.value });
      });

      window.addEventListener("resize", this.onWindowResizedDebounced);
   }

   private onWindowResizedDebounced = debounce(() => this.onWindowResized(), 1000, { leading: false, trailing: true });

   beforeDestroy() {
      window.removeEventListener("resize", this.onWindowResized);
   }

   onWindowResized() {
      EventBus.$emit(Events.ResizeEnd);
   }

   hideSnack(snackbar) {
      snackbar.active = false;
      this.snackbars = this.snackbars.filter((x) => x.active);
   }
}
</script>
<style scoped></style>
<style>
.bc-color {
   background-color: rgb(245, 245, 245);
}

.app-scroll {
   overflow-y: scroll;
}

a,
a:hover {
   text-decoration: none !important;
}
.snack-break {
   word-break: break-word;
}
</style>
