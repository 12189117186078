import { ContentBrickType, MenuItem } from "@backend/api/pmToolApi";
import { location } from "@root/routes";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import { Location } from "vue-router";
import { TranslationKey } from "@root/translations";

export interface NavItemLeaf {
   uiSelector: string;
   icon: `mdi-${string}`;
   labelKey: TranslationKey;
   tooltipKey: TranslationKey;
   location: Location;
   menuItem?: MenuItem;
}

export interface NavItemGroup {
   uiSelector: string;
   children: NavItem[];
   icon: `mdi-${string}`;
   labelKey: TranslationKey;
   tooltipKey: TranslationKey;
}

export type NavItem = NavItemLeaf | NavItemGroup;

export function isGroup(x: NavItem): x is NavItemGroup {
   return "children" in x;
}

export function filterLeafsAndPruneEmptyNodes(items: NavItem[], predicate: (x: NavItemLeaf) => boolean): NavItem[] {
   return items.flatMap((item) => {
      if (isGroup(item)) {
         const children = filterLeafsAndPruneEmptyNodes(item.children, predicate);
         return (children.length > 0 ? [{ ...item, children }] : []) as NavItem[];
      }
      return [item].filter(predicate);
   });
}

export const navItems: NavItem[] = [
   {
      uiSelector: "nav-tasks",
      icon: "mdi-list-status",
      labelKey: "navigationMenu.tasksLink",
      tooltipKey: "navigationMenu.tasksLinkTooltip",
      location: location("task-list"),
   },
   // Dashboard commented as long as it is not functional
   /*{
      uiSelector: "nav-dashboard",
      icon: "mdi-view-dashboard",
      labelKey: "Dashboard",
      tooltipKey: "Dashboard",
      route: location("home"),
   },*/
   {
      uiSelector: "nav-project-active",
      icon: "mdi-cube",
      labelKey: "navigationMenu.activeProjectsLink",
      tooltipKey: "navigationMenu.activeProjectsLinkTooltip",
      location: location("project-list"),
      menuItem: MenuItem.Projects,
   },
   {
      uiSelector: "nav-project-draft",
      icon: "mdi-cube-outline",
      labelKey: "navigationMenu.draftProjectsLink",
      tooltipKey: "navigationMenu.draftProjectsLinkTooltip",
      location: location("project-list-draft"),
      menuItem: MenuItem.Projects,
   },
   {
      uiSelector: "nav-domain-model-instances",
      icon: "mdi-database",
      labelKey: "navigationMenu.domainModelInstancesLink",
      tooltipKey: "navigationMenu.domainModelInstancesLinkTooltip",
      location: location("domain-data-model-instance-list"),
      menuItem: MenuItem.DomainModelInstances,
   },
   {
      uiSelector: "nav-reports",
      icon: "mdi-chart-bar",
      labelKey: "navigationMenu.reportsLink",
      tooltipKey: "navigationMenu.reportsLinkTooltip",
      location: location("report-list", { params: { tab: "grid" } }),
      menuItem: MenuItem.Reports,
   },
   {
      uiSelector: "nav-definitions",
      icon: "mdi-card-bulleted",
      labelKey: "navigationMenu.definitionsDropdownLabel",
      tooltipKey: "navigationMenu.definitionsDropdownLabelTooltip",
      children: [
         {
            location: location("domain-data-model-list"),
            icon: "mdi-database-outline",
            uiSelector: "nav-definitions-data-models",
            labelKey: "navigationMenu.domainModelsLink",
            tooltipKey: "navigationMenu.domainModelsLinkTooltip",
            menuItem: MenuItem.DomainDataModels,
         },
         {
            location: location("content-brick-list"),
            icon: ContentBrickTypeDecorator.get(ContentBrickType.ContentBrick).icon,
            uiSelector: "nav-definitions-content-bricks",
            labelKey: "navigationMenu.contentBricksLink",
            tooltipKey: "navigationMenu.contentBricksLinkTooltip",
            menuItem: MenuItem.ContentBricks,
         },
         {
            location: location("sub-content-brick-list"),
            icon: "mdi-cube-outline",
            uiSelector: "nav-definitions-sub-content-bricks",
            labelKey: "navigationMenu.subContentBricksLink",
            tooltipKey: "navigationMenu.subContentBricksLinkTooltip",
            menuItem: MenuItem.SubContentBricks,
         },
         {
            location: location("pbb-overview"),
            icon: "mdi-file-tree",
            uiSelector: "nav-definitions-pbb",
            labelKey: "navigationMenu.pbbsLink",
            tooltipKey: "navigationMenu.pbbsLinkTooltip",
            menuItem: MenuItem.ProcessBuildingBlocks,
         },
         {
            location: location("pbb-queries-overview"),
            icon: "mdi-view-headline",
            uiSelector: "nav-definitions-queries",
            labelKey: "navigationMenu.pbbQueriesLink",
            tooltipKey: "navigationMenu.pbbQueriesLinkTooltip",
            menuItem: MenuItem.ProcessBuildingBlockQueries,
         },
         {
            location: location("library-list"),
            icon: "mdi-bookshelf",
            uiSelector: "nav-definitions-general-library",
            labelKey: "navigationMenu.generalModelsLink",
            tooltipKey: "navigationMenu.generalModelsLinkTooltip",
            menuItem: MenuItem.GeneralDataModels,
         },
      ],
   },
   {
      uiSelector: "nav-testContentBricks",
      icon: "mdi-test-tube",
      labelKey: "navigationMenu.testContentBricksDropdownLabel",
      tooltipKey: "navigationMenu.testContentBricksDropdownLabelTooltip",
      children: [
         {
            location: location("design-guideline-list"),
            icon: ContentBrickTypeDecorator.get(ContentBrickType.DesignGuideline).icon,
            uiSelector: "nav-definitions-design-guidelines",
            labelKey: "navigationMenu.designGuidelinesLink",
            tooltipKey: "navigationMenu.designGuidelinesLinkTooltip",
            menuItem: MenuItem.DesignGuideLines,
         },
         {
            location: location("practical-test-list"),
            icon: ContentBrickTypeDecorator.get(ContentBrickType.PracticalTest).icon,
            uiSelector: "nav-definitions-pracitcal-tests",
            labelKey: "navigationMenu.practicalTestLink",
            tooltipKey: "navigationMenu.practicalTestLinkTooltip",
            menuItem: MenuItem.PracticalTests,
         },
         {
            location: location("general-test-list"),
            icon: ContentBrickTypeDecorator.get(ContentBrickType.GeneralTest).icon,
            uiSelector: "nav-testContentBricks-general-tests",
            labelKey: "navigationMenu.generalTestLink",
            tooltipKey: "navigationMenu.generalTestLinkTooltip",
            menuItem: MenuItem.GeneralTests,
         },
         {
            location: location("norms-and-standards-list"),
            icon: ContentBrickTypeDecorator.get(ContentBrickType.NormsAndStandards).icon,
            uiSelector: "nav-testContentBricks-norms-and-standards",
            labelKey: "navigationMenu.normsAndStandardsLink",
            tooltipKey: "navigationMenu.normsAndStandardsLinkTooltip",
            menuItem: MenuItem.NormsAndStandards,
         },
         {
            location: location("material-compliance-list"),
            icon: ContentBrickTypeDecorator.get(ContentBrickType.MaterialCompliance).icon,
            uiSelector: "nav-testContentBricks-material-compliance",
            labelKey: "navigationMenu.materialCompliancesLink",
            tooltipKey: "navigationMenu.materialCompliancesLinkTooltip",
            menuItem: MenuItem.MaterialCompliances,
         },
         {
            location: location("pre-shipment-instructions-list"),
            icon: ContentBrickTypeDecorator.get(ContentBrickType.PreShipmentInstructions).icon,
            uiSelector: "nav-testContentBricks-pre-shipment-instructions",
            labelKey: "navigationMenu.preShipmentInstructionsLink",
            tooltipKey: "navigationMenu.preShipmentInstructionsLinkTooltip",
            menuItem: MenuItem.PreShipmentInstructions,
         },
      ],
   },
   {
      uiSelector: "nav-administration",
      icon: "mdi-cog",
      labelKey: "navigationMenu.administrationDropdownLabel",
      tooltipKey: "navigationMenu.administrationDropdownLabelTooltip",
      children: [
         {
            location: location("automatic-import-overview"),
            icon: "mdi-import",
            uiSelector: "nav-administration-automatic-imports",
            labelKey: "navigationMenu.automaticImportsLink",
            tooltipKey: "navigationMenu.automaticImportsLinkTooltip",
            menuItem: MenuItem.AutomaticImports,
         },
         {
            location: location("capability-list"),
            icon: "mdi-certificate",
            uiSelector: "nav-administration-capabilities",
            labelKey: "navigationMenu.capabilitiesLink",
            tooltipKey: "navigationMenu.capabilitiesLinkTooltip",
            menuItem: MenuItem.Capabilities,
         },
         {
            location: location("data-table-list"),
            icon: "mdi-table",
            uiSelector: "nav-administration-data-tables",
            labelKey: "navigationMenu.dataTablesLink",
            tooltipKey: "navigationMenu.dataTablesLinkTooltip",
            menuItem: MenuItem.DataTables,
         },
         {
            location: location("domain-list"),
            icon: "mdi-account-group",
            uiSelector: "nav-administration-domains",
            labelKey: "navigationMenu.domainsLink",
            tooltipKey: "navigationMenu.domainsLinkTooltip",
            menuItem: MenuItem.Domains,
         },
         {
            location: location("enactment-priority-label-overview"),
            icon: "mdi-priority-high",
            uiSelector: "nav-definitions-enactment-priority-labels",
            labelKey: "navigationMenu.enactmentPriorityLabelsLink",
            tooltipKey: "navigationMenu.enactmentPriorityLabelsLinkTooltip",
            menuItem: MenuItem.EnactmentPriorityLabels,
         },
         {
            location: location("regular-expression-list"),
            icon: "mdi-view-headline",
            uiSelector: "nav-administration-regular-expressions",
            labelKey: "navigationMenu.formattingLink",
            tooltipKey: "navigationMenu.formattingLinkTooltip",
            menuItem: MenuItem.Formatting,
         },
         {
            location: location("list-overview"),
            icon: "mdi-format-list-bulleted",
            uiSelector: "nav-administration-lists",
            labelKey: "navigationMenu.listsLink",
            tooltipKey: "navigationMenu.listsLinkTooltip",
            menuItem: MenuItem.Lists,
         },
         // temporarily deactivated
         // {
         //    url: "/publicholidays/overview",
         //    icon: "mdi-map-clock",
         //    uiSelector: "nav-administration-public-holidays",
         //    labelKey: "navigationMenu.publicHolidaysLink",
         //    tooltipKey: "navigationMenu.publicHolidaysLinkTooltip",
         // },
         {
            location: location("qualification-overview"),
            icon: "mdi-account-box-outline",
            uiSelector: "nav-administration-qualifications",
            labelKey: "navigationMenu.qualificationsLink",
            tooltipKey: "navigationMenu.qualificationsLinkTooltip",
            menuItem: MenuItem.Qualifications,
         },
         {
            location: location("tag-list"),
            icon: "mdi-tag",
            uiSelector: "nav-administration-tags",
            labelKey: "navigationMenu.tagsLink",
            tooltipKey: "navigationMenu.tagsLinkTooltip",
            menuItem: MenuItem.Tags,
         },
         {
            location: location("translations"),
            icon: "mdi-translate",
            uiSelector: "nav-administration-translations",
            labelKey: "navigationMenu.translationsLink",
            tooltipKey: "navigationMenu.translationsLinkTooltip",
            menuItem: MenuItem.Translations,
         },
         {
            location: location("units-type-list"),
            icon: "mdi-numeric-1-box-multiple-outline",
            uiSelector: "nav-administration-unit-types",
            labelKey: "navigationMenu.unitTypesLink",
            tooltipKey: "navigationMenu.unitTypesLinkTooltip",
            menuItem: MenuItem.UnitTypes,
         },
         {
            location: location("units-list"),
            icon: "mdi-weight-kilogram",
            uiSelector: "nav-administration-units",
            labelKey: "navigationMenu.unitsLink",
            tooltipKey: "navigationMenu.unitsLinkTooltip",
            menuItem: MenuItem.Units,
         },
         {
            location: location("usage-types"),
            icon: "mdi-blur",
            uiSelector: "nav-administration-usage-types",
            labelKey: "navigationMenu.usageTypesLink",
            tooltipKey: "navigationMenu.usageTypesLinkTooltip",
            menuItem: MenuItem.UsageTypes,
         },
         {
            location: location("technical-measure-list"),
            icon: "mdi-arrow-expand-horizontal",
            uiSelector: "nav-administration-technical-measures",
            labelKey: "navigationMenu.technicalMeasuresLink",
            tooltipKey: "navigationMenu.technicalMeasuresLinkTooltip",
            menuItem: MenuItem.TechnicalMeasures,
         },
      ],
   },
   {
      uiSelector: "nav-userAdministration",
      icon: "mdi-account-cog",
      labelKey: "navigationMenu.userAdministrationDropdownLabel",
      tooltipKey: "navigationMenu.userAdministrationDropdownLabelTooltip",
      children: [
         {
            location: location("group-list"),
            icon: "mdi-account-supervisor-circle",
            uiSelector: "nav-userAdministration-groups",
            labelKey: "navigationMenu.groupsLink",
            tooltipKey: "navigationMenu.groupsLinkTooltip",
            menuItem: MenuItem.UserGroups,
         },
         {
            location: location("org-chart"),
            icon: "mdi-graph",
            uiSelector: "nav-userAdministration-organigram",
            labelKey: "navigationMenu.organigramLink",
            tooltipKey: "navigationMenu.organigramLinkTooltip",
            menuItem: MenuItem.UserOrganigram,
         },
         {
            location: location("role-list"),
            icon: "mdi-account-tie",
            uiSelector: "nav-userAdministration-roles",
            labelKey: "navigationMenu.rolesLink",
            tooltipKey: "navigationMenu.rolesLinkTooltip",
            menuItem: MenuItem.Roles,
         },
         {
            location: location("user-list"),
            icon: "mdi-account-supervisor",
            uiSelector: "nav-userAdministration-users",
            labelKey: "navigationMenu.usersLink",
            tooltipKey: "navigationMenu.usersLinkTooltip",
            menuItem: MenuItem.Users,
         },
      ],
   },
];
