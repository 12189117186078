<template>
   <add-reference
      v-bind="{
         ...$props,
         value: value == undefined ? [] : [value],
         required: required,
         maxOneReference: true,
      }"
      v-on="{
         ...$listeners,
         input: (x) => $emit('input', x[0]),
      }"
   >
      <template v-for="(_, slot) in $slots" #[slot]>
         <slot :name="slot" />
      </template>
      <template v-for="(_, slot) in $scopedSlots" #[slot]="scope">
         <slot :name="slot" v-bind="scope" />
      </template>
   </add-reference>
</template>

<script lang="ts">
import { ItemReference } from "@backend/api/pmToolApi";
import { ValidationRule } from "@models/shared/ValidationRules";
import ViewItem from "@models/view/ViewItem";
import { RoutePathWithoutParams, RoutePathWithParams } from "@root/routes";
import { PagingStore } from "@utils/Paging";
import { Component, Prop, Vue } from "vue-property-decorator";
import AddReference from "@components/Shared/add-reference.vue";

/**
 * Convenience wrapper for the AddReference component for cases of at most one reference.
 */
@Component({
   name: "AddReferenceSingle",
   components: {
      AddReference,
   },
})
export default class AddReferenceSingle extends Vue {
   // Custom props:

   @Prop({ default: false, type: Boolean })
   required: boolean;

   @Prop({ required: true })
   value: ItemReference | undefined;

   // Forwarded props:

   @Prop({ default: "Reference" })
   entity: string;

   @Prop({ default: null })
   newItemRoute: RoutePathWithoutParams;

   @Prop({ default: false })
   showExpand: boolean;

   /**
    * API endpoint which is used to get and select references via popup dialog with table
    * (may be serverside paginated -> returning paged data, or non-serverside paginated -> returning all references at once)
    */
   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   apiEndpoint: (pagingStore: PagingStore, domain?: number, search?: string) => Promise<ItemReference[]>;

   @Prop({ default: false })
   disabled: boolean;

   @Prop({ default: false })
   hideDetails: boolean;

   @Prop({ default: false })
   singleLine: boolean;

   @Prop()
   headers?: ViewItem[];

   @Prop({ default: () => [] })
   rules: ValidationRule<ItemReference | null | undefined>[];

   @Prop({ default: true })
   hideNoData: boolean;

   @Prop({ default: null })
   createApiEndpoint: (value: string) => Promise<ItemReference> | null;

   @Prop({ default: null })
   itemDetailRoute: RoutePathWithParams;

   @Prop({ default: "mdi-upload" })
   icon: string;

   @Prop({ default: false })
   showOnlyButton;
   /**
    * Whether or not to show domain selection.
    * When set to true, "apiEndpoint" is expected to have a "domain" parameter, in order to load references tied to the given domain
    */
   @Prop({ default: false })
   showDomainSelect: boolean;

   @Prop({ default: true })
   showLabelOnInput: boolean;

   @Prop({ default: undefined })
   sortBy?: string | [];

   @Prop({ default: undefined })
   sortDesc?: boolean | [];

   /**
    * Should return a reason why the item is readonly or `undefined` if it is not.
    */
   @Prop({ default: undefined })
   readOnlyItemReason?: (x: ItemReference) => string | undefined;
}
</script>
