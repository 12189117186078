import { LanguagePublicModel, TranslationApi } from "@backend/api/pmToolApi";
import { CachedApiBase } from "@backend/store/apiCache/CachedApiBase";

class TranslationCachedApi extends CachedApiBase {
   private readonly storedTranslationRoutesKey: string = "TranslationApi.storedTranslationRoutes";
   private readonly translationsRoutePrefix: string = "TranslationApi.getRouteTranslations_";
   private static readonly getActiveLanguagesKey = "TranslationApi.getActiveLanguages";

   prefixRoute(route: string) {
      return this.translationsRoutePrefix + route;
   }

   /**
    * Returns list of active languages
    */
   async getActiveLanguages(): Promise<LanguagePublicModel[]> {
      return await this.cacheEndpointAsync<LanguagePublicModel>(
         TranslationCachedApi.getActiveLanguagesKey,
         () => TranslationApi.getAllLanguages(true),
         LanguagePublicModel.fromJS
      );
   }

   invalidateAll() {
      this.invalidateItem(TranslationCachedApi.getActiveLanguagesKey);
   }

   private static _instance: TranslationCachedApi;

   private constructor() {
      super();
   }

   public static get instance(): TranslationCachedApi {
      return this._instance || (this._instance = new this());
   }
}

export default TranslationCachedApi.instance;
