var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('add-reference',_vm._g(_vm._b({scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'add-reference',{
      ..._vm.$props,
      value: _vm.value == undefined ? [] : [_vm.value],
      required: _vm.required,
      maxOneReference: true,
   },false),{
      ..._vm.$listeners,
      input: (x) => _vm.$emit('input', x[0]),
   }))
}
var staticRenderFns = []

export { render, staticRenderFns }